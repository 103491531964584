import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import rizoom from '../../assets/images/rizoom-contact.svg';
import Grid from '../Grid';
import vimeo from '../../assets/images/w_vimeo.svg';
import instagram from '../../assets/images/w_instagram.svg';
import facebook from '../../assets/images/w_facebook.svg';

const CoverFrontStyles = styled.div`
  background: var(--red);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
`;

const ContentStyles = styled.div`
  max-width: var(--grid-width);
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px;
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 15px;
`;
const ImageColumn = styled.div`
  @media screen and (max-width: 39.9375em) {
    grid-column: 1 / span 8;
    img {
      width: 296px;
    }
  }
  grid-column: 2 / span 7;
  height: auto;
  margin-bottom: 30px;
  @media screen and (min-width: 64em) {
    grid-column: 2 / span 10;
    margin-bottom: 90px;
  }
  img {
    width: 100%;
  }
`;
const Column = styled.div`
  grid-column: span 6;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: span 3;
  }
  @media screen and (min-width: 64em) {
    grid-column: span 4;
    &.info {
      grid-column: span 4;
    }
    &.socials {
      grid-column: span 4;
    }
   &.stichting {
      grid-column: 2 / span 11;
   }
  }
  address {
    font-style: normal;
  }

  &:nth-child(2) {
    @media screen and (max-width: 39.9375em) {
      display: flex;
      justify-content: center;
    }
    grid-column: 1 / span 12;
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: 2 / span 3;
    }

    @media screen and (min-width: 64em) {
      grid-column: 2 / span 3;
    }
  }
  a {
    color: white;
    display: block;
    text-decoration: none;
    font-family: 'regular';
    &.download {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  p {
    line-height: 33px;
  }
  .social {
    margin-right: 15px;
    transform: translateY(5px);
  }
  .circle {
    @media screen and (max-width: 39.9375em) {
      width: 110px;
      height: 110px;
      span {
        font-size: 19px;
      }
    }
    @media screen and (min-width: 40em) {
      width: 150px;
      height: 150px;
      span {
        font-size: 27px;
      }
    }
    background: white;
    border-radius: 50%;
    transform: rotate(30deg);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-family: 'bold';
      color: var(--red);
    }
  }
`;

export default function CoverContact({
  vimeoUrl,
  facebookUrl,
  instagramUrl,
  mail,
  phone,
  place,
  zipcode,
  street,
}) {
  const divRref = useRef(null);

  function scrollToBlock() {
    divRref.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <CoverFrontStyles>
        <ContentStyles>
          <ImageColumn>
            <img src={rizoom} width="798" alt="Rizoom" />
          </ImageColumn>
          <Column>
            <motion.div
              className="circle"
              whileHover={{ rotate: 40 }}
              onClick={scrollToBlock}
            >
              <span>Tip ons</span>
            </motion.div>

          </Column>
          <Column className="info">
            <b>Contact</b>
            <address>
              {street}
              <br />
              {zipcode}
              <br />
              {place}
              <br />
            </address>
         
              <a href={`mailto:${mail}`}>{mail}</a>
              {/* <a href={`tel:${phone}`}>{phone}</a> */}
              <p>
              {vimeoUrl && (
                <a href={vimeoUrl} target="_blank">
                  <img
                    src={vimeo}
                    width="24"
                    className="social"
                    height="21"
                    alt="Vimeo"
                  />
                  Vimeo
                </a>
              )}
              {instagramUrl && (
                <a href={instagramUrl} target="_blank">
                  <img
                    src={instagram}
                    width="24"
                    className="social"
                    height="21"
                    alt="Instagram"
                  />
                  Instagram
                </a>
              )}
              {/* {facebookUrl && (
                <a href={facebookUrl} target="_blank">
                  <img
                    src={facebook}
                    width="24"
                    className="social"
                    height="21"
                    alt="Facebook"
                  />
                  Facebook
                </a>
              )} */}
            </p>

          </Column>
          <Column className="socials">
            
              <b>Team en RvT</b>
              <br />
              Kwartiermaker: Milena Haverkamp<br /><br />
              Raad van Toezicht:<br />
              Voorzitter: Maruška Lestrade<br />
              Leden: Cynthia Barron, Zippora Elders, Joachim Junghanss
{/* 
              <small style={{ lineHeight: '28px', display: 'inline-block' }}>
                Maruška Lestrade - Voorzitter,
                <br />
                Cynthia Barron, Zippora Elders, Joachim Junghanss
                <br />
                RSIN 860884077
                <br />
                <a
                  className="download"
                  href="https://www.rizoom.net/BELEIDSPLAN_-_Website_Stichting_Rizoom_21_mei_2022.docx"
                  target="_blank"
                >
                  BELEIDSPLAN - Stichting Rizoom
                </a>
              </small> */}

          </Column>
          <Column className="stichting">
            <b>Stichting Rizoom</b><br />
            RSIN: 860884077<br />
            KvK: 77049616<br /><br />
            Adres: Piet Heinkade 1, 1019BR Amsterdam<br />
            Datum van oprichting: januari 2020<br /><br />
            <b>ANBI</b><br />
            Stichting Rizoom staat bij de Belastingdienst geregistreerd als een Algemeen Nut Beogende Instelling (ANBI).<br /><br />
            <b>RVT</b><br />
            Stichting Rizoom wordt bestuurd  wordt bestuurd door een kwartiermaker en gecontroleerd door de Raad van Toezicht.<br /><br />
            <b>VERKORT BELEIDSPLAN</b><br />
            Rizoom is een initiatief van de belangrijkste vertegenwoordigers van de Nederlandse hedendaagse muziekcultuur. De handen zijn ineengeslagen om jonge makers van nieuwe muziek een platform te bieden om zich te ontwikkelen en een podium te geven om van zich te laten horen.<br />
            Jonge makers worden gevraagd een onderzoeksplan in te dienen. Deze worden door externe makers en partners beoordeeld. Jaarlijks worden maximaal zes makers begeleid en gefinancierd in hun onderzoek. Deze worden minimaal 1x gepresenteerd tijdens een openbare avond bij een van de partners.<br />
            Doelstellingen in het kort: Het faciliteren, begeleiden en ontwikkelen van talentvolle innovatieve (muziek)makers.  Door makers te begeleiden, financieren en op verschillende manieren te faciliteren bij de verschillende partners, is er ontwikkeling en netwerk verbreding mogelijk.<br /><br />
            <b>BELONINGSBELEID</b><br />
            De Raad van Toezicht is een onbezoldigde. Het beloningsbeleid van Stichting Rizoom is erop gericht haar medewerkers te belonen volgens maatstaven die maatschappelijk aanvaardbaar zijn, passend bij haar status als culturele instelling. De stichting volgt de CAO Toneel & Dans. De richtlijn voldoet aan de kernwaarden van de Fair Practice Code.<br /><br />
            <b>VERSLAGLEGGING</b><br />
            <a href="/jaarverslag2022.pdf">Jaar 2022</a><br />
            <a href="/jaarverslag2021.pdf">Jaar 2021</a>
          </Column>
          <Column />
        </ContentStyles>
      </CoverFrontStyles>
      <div ref={divRref} />
    </>
  );
}
